<template lang="pug">
include ../../../configs/template
div.row.mx-1.text-left.pb-5
  div.col-12
    +data-info('createDate','statement.created_at')
  div.col-sm-12.col-md-6
    +data-info('sailorFullName','sailorFullName')
  div.col-sm-12.col-md-6
    +data-info('filter.birthDay','statement.sailor.birth_date')
  div.col-sm-12.col-md-6
    +data-info('crewingCompany','statement.crewing_company')
  div.col-sm-12.col-md-6
    +data-info('tableHeaders.manager_full_name','statement.manager_full_name')
  div.col-12
    +data-info-status('tableHeaders.status_document','getDirectoryObject({ id:statement.status_document, value: "statuses" })[nameLang]', 'getStatus(statement.status_document)')
  div(v-if="statement.statement_service_record").col-12
    v-btn(color="primary" @click="$router.push(`/sailor/${statement.sailor.id}/experience/statements/${statement.statement_service_record}`)") {{$t('transferStatement')}}
  div(v-if="statement.comments?.length").col-12
    div.row.mx-1
      b.mr-3 {{$t('setComment')}}:
      div.d-flex.flex-column
        div(v-for="item in statement.comments" :key="`statement-recrd-book-isc-${item.id}`") {{item.comment}};
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { getStatus } from '@/mixins/main'

export default {
  props: { statement: Object, default: () => ({}) },
  data () {
    return { getStatus }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['getDirectoryObject']),
    sailorFullName () {
      return this.statement.sailor[`full_${this.nameLang}`] || ''
    }
  }
}
</script>
